import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import moment from "moment";

Vue.config.productionTip = false
Vue.filter("formatDate", (d) => {
    if (!d) return "";
    return moment(d, "YYYY-MM-DD").format("DD/MMM/YYYY");
})

Vue.filter("getCurrency", (d) => {
    if (!d) return "";
    let sy="";
    switch (d){
        case "USD":
            sy="$";
            break;
        case "KHR":
            sy="៛";
            break;
        case "THB":
            sy="B";
            break;
        default:
            sy="$";
            break;
    }
    return sy;
})

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')
